.loan-settings {
    /*border: 1px solid red;*/
}

.loan-settings-header {
    margin-bottom: 5px;
}

.loan-settings-header-reward-icon {
    width: 23px;
    height: 23px;
    cursor: pointer;
    margin-top: -5px;
    padding-left: 5px;
}

.loan-settings-header-max {
    cursor: pointer;
    color: #3E6FE1;
}

.loan-settings-header-max:hover {
    color: #2B51A9;
}

.loan-settings-buttons {
    border: 1px solid #454F5B;
    height: 60px;
    border-radius: 12px;
}
.loan-settings-header-balance{
    font-size: 12px;
    padding-right: 20px;
    color: #9597a1;
}
.loan-repay-header-text {
    padding-top: 5px;
}
.loan-repay-header-date {
    padding-top: 5px;
    font-size: 12px;
    color: #9597a1;
}
.loan-settings-img {
    cursor: pointer;
    margin-top: 13px;
}

.new-loan-info-text{
    color: #9597a1;
}
.new-loan-info-value{

}
@media only screen and (max-width: 470px) {
    .loan-settings-header-text {
        font-size: 2.7vw;
    }
    .new-loan-info-value{
        font-size: 3.5vw;
    }
    .new-loan-info-text{
        font-size: 3.5vw;
    }
    .loan-repay-header-text {
        padding-top: 5px;
        font-size: 2.6vw;
    }
    .loan-repay-header-date {
        font-size: 2.7vw;
    }
    .loan-settings-header-balance{
        font-size: 2.5vw;
    }
    .loan-settings-img {
    }
    .loan-settings-header-reward-icon{
        width: 20px;
        height: 20px;
    }
}
@media only screen and (max-width: 350px) {
    .loan-settings-header-reward-icon{
        width: 18px;
        height: 17px;
    }
    .loan-settings-header-text {
        font-size: 2.5vw;
    }
    .loan-settings-header-balance{
        font-size: 2.3vw;
    }
    .date-time-Button-text{
        font-size: 4vw;
    }
}