
.balance-text{
    font-size: 12px;
}
@media only screen and (max-width: 540px) {
    .header-text{
        font-size: 2.7vw;
    }
    .balance-text{
        font-size: 2vw;
    }
}