.Toastify__toast-theme--dark{
  --toastify-color-dark: rgba(24, 37, 57, 0.8);
  --toastify-font-family: Rubik;
  --toastify-text-color-dark: #ECECEC;
  --toastify-color-progress-dark: #243b55;
}

body {
  margin: 0;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to right, #243b55, #182539);
  color: #ECECEC;
  width: 100vw;
  overflow-x: hidden;
  letter-spacing: 1px;
  line-height: 18px;
  font-size: 1rem;
}
*::-webkit-scrollbar{
  width: 5px;
  height: 5px;
}
*::-webkit-scrollbar:hover{
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  background: rgba(31, 32, 28, 0);        /* color of the tracking area #1f2028 */
}
*::-webkit-scrollbar-thumb {
  background-color: #294171;
  border-radius: 20px;
  background-clip: padding-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn{
  letter-spacing: 1px;
  color: #ECECEC;
  font-size: 1rem;
}
.modal-content {
  background-color: #182539;
  border-radius: 20px;
}
.btn-dark {
  --bs-btn-bg: #203349;
  --bs-btn-border-color: #454F5B;
  --bs-btn-hover-bg: #182539;
}
.btn-check:checked+.btn,
.btn.active, .btn.show,
.btn:first-child:active, :not(.btn-check)+.btn:active{
  background-color: #182539;
}
.dropdown-menu-dark {
  --bs-dropdown-bg: #203349;
  height: 300px;
  overflow: auto;
  border: 1px solid #9597a1;
}
#network-dropdown{
  width: 90%;
  max-width: 200px;
}
#info-dropdown{
  width: 100%;
}
#switch-m{
  margin-right: 5px;
  margin-top: 5px;
}
.dropdown-menu{
  --bs-dropdown-bg: #203349;
  border: 1px solid #9597a1;
  --bs-dropdown-link-color: #ECECEC;
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-hover-color: #ECECEC;
}
.form-check-input {
  margin: 0;
  background-color: #454F5B;
}
.form-check-input:checked{
  border-color: #182539;
  background-color: #182539;
}

.form-control{
  background-color:  #182539;
  color: #ECECEC;
  border: 1px solid #383944;
}
.form-control:focus{
  background-color:  #182539;
  color: #ECECEC;
  border: 1px solid #383944;
}
.form-control:active{
  background-color:  #182539;
  color: #ECECEC;
  border: 1px solid #383944;
}
.form-control:disabled {
  background-color:  #182539;
  color: #ECECEC;
  border: 1px solid #383944;
}
#new-loan-amount{
  height: 40px;
  border-radius: 8px;
}
#current-order-modal{
  padding: 0 35px;
}

#deposit-button{
  width: 90px;
  top: 13px;
  right: 12px;
  position: absolute;
  text-end-overflow: true;
  background-color: #203349;
  border: 1px solid #454F5B;
}
.filter-style{
  width: 100%;
  min-height: 70px;
  border-radius: 20px;
  background-color: #1c2c42;
  margin-bottom: 30px;
  padding-top: 14px;
  padding-left: 14px;
}
.market-br{
  display: none;
}
@media only screen and (max-width: 750px){

}
@media only screen and (max-width: 430px){
  #current-order-modal{
    padding: 0;
  }
  #new-loan-amount{
    width: 80px;
  }
  #deposit-button{
    width: 90px;
    right: 0;
    font-size: 15px;
  }
  .new-loan-info-text{

  }
}

@media only screen and (max-width: 390px){
  .header-text{
    font-size: 12px;
  }
  #new-loan-amount{
    width: 60px;
  }
  #deposit-button{
    width: 80px;
    right: 0px;
    font-size: 15px;
  }
}
@media only screen and (max-width: 360px){
  .market-text{
    font-size: 14px;
  }
  .market-br{
    display: block;
    margin-bottom: 10px;
  }
  .header-text{
    font-size: 10px;
  }
  #new-loan-amount{
    width: 60px;
  }
  #deposit-button{
    width: 80px;
    right: 0px;
    font-size: 15px;
  }
  .menu-button{
    letter-spacing: -1px;
  }
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: inner-spin-button !important;
  width: 25px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}
.new-amount{
  height: 40px;
  border-radius: 8px;
}
.datepicker-modal{
  z-index: 1060 !important;
}
.color{
  color: #A2F3D5;
}
.dd{
  color: #3cB831;}